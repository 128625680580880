<script setup lang="ts">
    const { InstantSearch } = useMeili();
    const { isMobile } = useViewport();

    const isFiltersDrawerOpen = useState('isFiltersDrawerOpen', () => false);
</script>

<template>
    <InstantSearch>
        <div class="flex h-screen flex-col overflow-hidden">
            <!-- add v-if to FiltersDrawer? -->
            <FiltersDrawer />
            <CartDrawer />
            <MobileHeader v-if="isMobile" />
            <TheHeader v-else />
            <div class="flex flex-grow overflow-hidden">
                <Sidebar v-if="!isMobile" />
                <slot />
            </div>
            <TheFooter />
        </div>
    </InstantSearch>
</template>
