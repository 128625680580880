<script setup>
    import {
        Command,
        CommandEmpty,
        CommandGroup,
        CommandInput,
        CommandItem,
        CommandList,
    } from '@/components/ui/command';

    import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

    import { cn } from '@/lib/utils';
    import { CheckIcon } from '@radix-icons/vue';

    import { languages } from '~/labels';

    const open = ref(false);
    const preferredLanguage = usePreferredLanguage();

    const languagesList = computed(() =>
        Object.entries(languages).map(([value, label]) => ({ value, label }))
    );

    function handleSelect(code) {
        preferredLanguage.value = [code];
        open.value = false;
    }

    function handleSelectAll() {
        preferredLanguage.value = [];
        open.value = false;
    }
</script>

<template>
    <Popover v-model:open="open">
        <PopoverTrigger as-child>
            <Button
                variant="outline"
                role="combobox"
                :aria-expanded="open"
                class="h-[32px] w-[40px] px-1 py-0"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                    />
                </svg>
            </Button>
        </PopoverTrigger>
        <PopoverContent class="mr-4 w-[180px] p-0">
            <Command>
                <CommandInput
                    class="h-9"
                    placeholder="Search language..."
                />
                <CommandEmpty>No language found.</CommandEmpty>
                <CommandList>
                    <CommandGroup>
                        <!-- "Show all languages" option -->
                        <CommandItem
                            :key="'all-languages'"
                            :value="null"
                            @select="handleSelectAll"
                        >
                            Show all languages
                            <CheckIcon
                                :class="
                                    cn(
                                        'ml-auto h-4 w-4',
                                        preferredLanguage.length === 0
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    )
                                "
                            />
                        </CommandItem>

                        <!-- Language options -->
                        <CommandItem
                            v-for="language in languagesList"
                            :key="language.value"
                            :value="language.label"
                            @select="handleSelect(language.value)"
                        >
                            {{ language.label }}
                            <CheckIcon
                                :class="
                                    cn(
                                        'ml-auto h-4 w-4',
                                        preferredLanguage[0] === language.value
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    )
                                "
                            />
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
