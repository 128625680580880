<script setup lang="ts">
    const preferredLanguage = usePreferredLanguage();
    const { globalAssets } = useGlobalAssets();
    const route = useRoute();

    const refreshState = () => {
        if (route.path === '/') {
            preferredLanguage.value = [];
            globalAssets.value = [];
            window.location.reload();
        }
    }
</script>

<template>
    <header class="w-full flex-shrink-0 border-b-2">
        <div class="mx-auto px-4 py-6 sm:px-6 lg:px-8">
            <div class="mb-6 flex flex-col items-center">
                <div class="flex w-full items-center justify-between">
                    <div class="flex w-1/3 items-center justify-start">
                        <MobileMenuIcon />
                    </div>
                    <NuxtLink
                        to="/"
                        class="w-1/3 text-center"
                        @click="refreshState"
                    >
                        <h1 class="text-[1.2rem] font-bold">Flügger</h1>
                    </NuxtLink>
                    <div class="flex w-1/3 items-center justify-end gap-3">
                        <MobileLanguageSwitcher />
                        <DownloadButton />
                    </div>
                </div>
            </div>
            <SearchBox />
        </div>
    </header>
</template>
