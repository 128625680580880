<script setup lang="ts">
    import { fileTypes } from '~/labels';

    const isFiltersDrawerOpen = useState('isFiltersDrawerOpen');

    const closeFiltersDrawer = () => {
        isFiltersDrawerOpen.value = false;
    };
</script>

<template>
    <aside>
        <Transition name="filters-fade">
            <div
                v-show="isFiltersDrawerOpen"
                class="fixed inset-0 z-50 h-full w-full bg-black/30 backdrop-blur-sm"
                @click="closeFiltersDrawer"
            >
                <Transition name="filters-slide">
                    <div
                        v-show="isFiltersDrawerOpen"
                        class="fixed end-0 flex h-full w-full flex-col items-center justify-start bg-white/90 md:w-1/3 overflow-y-auto"
                        @click.stop
                    >
                        <!-- Top row -->
                        <div class="flex min-w-full flex-col px-8 py-6">
                            <div class="flex w-full items-center justify-end">
                                <button
                                    type="button"
                                    @click="closeFiltersDrawer"
                                    class="btn btn-outline btn-sm border-gray-300 font-bold"
                                >
                                    <span class="sr-only">Close Cart</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6 18 18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <!-- Filters -->
                        <div class="flex w-full flex-col items-start px-8 py-6">
                            <h2 class="mb-6 font-bold">Filters</h2>

                            <div class="w-full mt-1 space-y-2">
                                <RefinementList
                                    label="Category"
                                    attribute="category"
                                />

                                <RefinementList
                                    label="Document types"
                                    attribute="document_types"
                                    :labels="fileTypes"
                                />

                                <RefinementList
                                    label="Voluntary schemes"
                                    attribute="labels"
                                />
                            </div>

                            <NuxtLink
                                to="/table"
                                class="mt-12 inline-block w-full rounded-md bg-black p-1 text-center text-white"
                                @click="closeFiltersDrawer"
                            >
                                Table View
                            </NuxtLink>

                            <CompanyCertificates />
                        </div>
                    </div>
                </Transition>
            </div>
        </Transition>
    </aside>
</template>
