<template>
    <footer class="h-[60px] flex-shrink-0 border-t-2">
        <div class="h-full flex items-center justify-between mx-auto px-4 sm:px-6 lg:px-8">
          <div class=" flex flex-col items-center justify-center text-xs">
            <p>© All rights reserved</p>
            <p>Flügger Group A/S</p>
          </div>
            <p class="text-xs">Lights on</p>
        </div>
    </footer>
</template>
